<template>
    <div class="flex justify-center px-4">
      <div class="w-full max-w-md bg-white p-8 shadow rounded-lg flex flex-col items-center pt-4 mt-5">
        <form class="w-full">
          <div class="mb-2">
            <label class="block text-sm font-bold mb-2" for="firstname">
              Voornaam
            </label>
            <input class="border rounded w-full py-2 px-3 leading-tight text-black bg-gray-100" :class="[error && !firstname ? 'border-primary' : '']" id="firstname" type="text" placeholder="Voornaam" v-model="firstname" required>
          </div>
          <div class="mb-2">
            <label class="block text-sm font-bold mb-2" for="lastname">
              Achternaam
            </label>
            <input class="border rounded w-full py-2 px-3 leading-tight text-black bg-gray-100" :class="[error && !lastname ? 'border-primary' : '']" id="lastname" type="text" placeholder="Achternaam" v-model="lastname" required>
          </div>
          <div>
            <label class="block text-sm font-bold mb-2" for="email">
              Emailadres
            </label>
            <input class="border rounded w-full py-2 px-3 leading-tight text-black bg-gray-100" :class="[error && !email ? 'border-primary' : '']" id="email" type="email" placeholder="Emailadres" v-model="email" required>
          </div>
          <div class="mt-4">
            <p v-if="error" class="text-primary text-sm font-semibold">{{ error }}</p>
          </div>
          <div class="rounded-lg text-white px-4 py-3 text-sm cursor-pointer bg-primary w-40 text-center mt-4" @click="createUser()" :disabled="!loading">
            Gebruiker aanmaken
          </div>
        </form>
      </div>
    </div>
</template>

<script>

export default {
  title: 'Gebruiker toevoegen',
  name: 'AddUser',
  data () {
    return {
      email: null,
      firstname: null,
      lastname: null,
      loading: false,
      error: null
    }
  },
  methods: {
    createUser () {
      const email = this.email
      const firstname = this.firstname
      const lastname = this.lastname
      const name = this.firstname + ' ' + this.lastname
      if (!email || !firstname || !lastname) return this.setError('Niet alles is juist ingevuld')
      if (!this.emailIsValid(email)) return this.setError('Emailadres is niet geldig')
      this.loading = true
      this.$store
        .dispatch('addUser', { email, name })
        .then(() => this.$router.push('/users'))
        .catch(err => this.setError(err))
    },
    setError (data) {
      this.loading = false
      this.error = data
    },
    emailIsValid (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
  }
}
</script>
